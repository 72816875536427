import React, { useContext } from 'react'
import { Button, Card, Container, Row, Col } from "react-bootstrap"

export const MainContent = ({ add, del, title, addButtonDataCy, children, context }) => {
    const { state } = useContext(context)
    return (
        <>
            {state.alert}
            <Container fluid className="pt-3">
                <Row>
                    <Col md="12">
                        <Row>
                            <Col>
                                <h4 className="title m-0">{title}</h4>
                            </Col>
                            <Col>
                                {del &&
                                    <div className="m-0">
                                        <Button
                                            data-cy={'deletePastEvents'}
                                            className="btn-wd btn-outline mr-1 float-right"
                                            type="button"
                                            variant="danger"
                                            style={{ width: '20%' }}
                                            onClick={del}
                                        >
                                            Delete Past Events
                                        </Button>
                                    </div>
                                }
                                {add &&
                                    <div className="m-0">
                                        <Button
                                            data-cy={addButtonDataCy}
                                            className="btn-wd btn-outline mr-1 float-right"
                                            type="button"
                                            variant="success"
                                            style={{ width: '10%' }}
                                            onClick={add}
                                        >
                                            <span className="btn-label">
                                                <i className="fas fa-plus" />
                                            </span>
                                            Add
                                        </Button>
                                    </div>
                                }
                            </Col>
                        </Row>
                        <Card>
                            <Card.Body>
                                {children}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
