import {ApiCore} from "./utilities";

const adsApi = new ApiCore({
    get: true,
    post: true,
    put: true,
    del: true
});

// Add custom api calls
adsApi.getAllAds = (filter = '') => adsApi.get(`/ad${filter}`);

adsApi.getAd = (id) => adsApi.get(`/ad/${id}`);

adsApi.deleteAd = (id) => adsApi.del(`/ad/${id}`);

adsApi.updateAd = (ad, id) => adsApi.put(`/ad/${id}`, ad);

adsApi.createAd = (ad) => adsApi.post(`/ad`, ad);

export default adsApi;
