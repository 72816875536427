import axios from 'axios';
import {handleResponse, handleError} from './response';
import {getAuth} from "firebase/auth";

// Define your api url from any source.
const BASE_URL = process.env.REACT_APP_API_ENDPOINT || '';

/** @param {string} resource */
const get = async (resource) => {
    let options = await getAxiosAuthOptions();
    return axios
        .get(`${BASE_URL}${resource}`, options)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const post = async (resource, model) => {
    let options = await getAxiosAuthOptions();
    return axios
        .post(`${BASE_URL}${resource}`, model, options)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const put = async (resource, model) => {
    let options = await getAxiosAuthOptions();
    return axios
        .put(`${BASE_URL}${resource}`, model, options)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource */
/** @param {string} id */
const del = async (resource) => {
    let options = await getAxiosAuthOptions();
    return axios
        .delete(`${BASE_URL}${resource}`, options)
        .then(handleResponse)
        .catch(handleError);
};

const getAxiosAuthOptions = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
        const bearer = `Bearer ${auth.currentUser.accessToken}`;

        return {
            headers: {Authorization: bearer}
        };
    } else {
        // No user is signed in.
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
};

export const apiProvider = {
    get,
    post,
    put,
    del,
};
