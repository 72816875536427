import React, {useState, useEffect, useCallback} from "react";
import {Link, useLocation} from "react-router-dom";
import {
    Collapse,
    Nav,
} from "react-bootstrap";
import {ProfileInfo} from "./ProfileInfo";

const Sidebar = ({routes, image, background}) => {
    // to check for active links and opened collapses
    let location = useLocation();

    // this is for the rest of the collapses
    const [state, setState] = useState({});

    // this function creates the links and collapses that appear in the sidebar (left menu)
    const createLinks = (routes) => {
        return routes.map((prop, key) => {
            if (prop.redirect) {
                return null;
            }
            if (prop.collapse) {
                let st = {};
                st[prop["state"]] = !state[prop.state];
                return (
                    <Nav.Item
                        data-cy={`${prop.name}MenuItem`}
                        className={getCollapseInitialState(prop.views) ? "active" : ""}
                        as="li"
                        key={key}
                    >
                        <Nav.Link
                            className={state[prop.state] ? "collapsed" : ""}
                            data-toggle="collapse"
                            onClick={(e) => {
                                e.preventDefault();
                                setState({...state, ...st});
                            }}
                            aria-expanded={state[prop.state]}
                        >
                            <i className={prop.icon}/>
                            <p>
                                {prop.name} <b className="caret"/>
                            </p>
                        </Nav.Link>
                        <Collapse in={state[prop.state]}>
                            <div>
                                <Nav as="ul">{createLinks(prop.views)}</Nav>
                            </div>
                        </Collapse>
                    </Nav.Item>
                );
            }
            return (
                <Nav.Item
                    data-cy={`${prop.name}MenuItem`}
                    className={activeRoute(prop.layout + prop.path)}
                    key={key}
                    as="li"
                >
                    <Nav.Link to={prop.layout + prop.path} as={Link}>
                        {prop.icon ? (
                            <>
                                <i className={prop.icon}/>
                                <p>{prop.name}</p>
                            </>
                        ) : (
                            <>
                                <span className="sidebar-mini">{prop.mini}</span>
                                <span className="sidebar-normal">{prop.name}</span>
                            </>
                        )}
                    </Nav.Link>
                </Nav.Item>
            );
        });
    };

    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
        return location.pathname === routeName ? "active" : "";
    };

    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
    const getCollapseInitialState = useCallback((routes) => {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (location.pathname === routes[i].layout + routes[i].path) {
                return true;
            }
        }
        return false;
    }, [location.pathname]);

    // this creates the intial state of this component based on the collapse routes
    // that it gets through routes prop
    const getCollapseStates = useCallback((routes) => {
        let initialState = {};
        routes.map((prop, key) => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: getCollapseInitialState(prop.views),
                    ...getCollapseStates(prop.views),
                    ...initialState,
                };
            }
            return null;
        });
        return initialState;
    }, [getCollapseInitialState]);

    useEffect(() => {
        setState(getCollapseStates(routes));
    }, [getCollapseStates, routes]);


    return (
        <>
            <div className="sidebar" data-color={background} data-image={image}>
                <div className="sidebar-wrapper">
                    <div className="logo d-flex align-items-center justify-content-center">
                        <a
                            className="simple-text logo-normal"
                            href="/"
                        >
                            <div>
                                <img
                                    src={process.env.PUBLIC_URL + '/logo192.png'}
                                    alt="..."
                                />
                            </div>
                        </a>
                    </div>
                    <Nav as="ul">{
                        <>
                            <ProfileInfo/>
                            {createLinks(routes)}
                        </>
                    }</Nav>
                </div>
                <div
                    className="sidebar-background"
                    style={{
                        backgroundImage: "url('" + image + "')",
                    }}
                />
            </div>
        </>
    );
};

export default Sidebar;
