import { Button, Col, Form, ListGroup, Modal, Row } from 'react-bootstrap'
import ReactDatetime from "react-datetime"
import React, { useContext, useReducer, useState, useEffect } from 'react'
import { Context as EventContext } from './EventContext'
import Loading from '../../components/Loading/Loading'
import { validateEventData } from './events.validation'
import GooglePlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-google-places-autocomplete'
// react plugin that creates an input with badges
import TagsInput from "components/TagsInput/TagsInput.js"
import { getTimeZone } from "../Pages.utils"
import uploadImageServiceApi from "../../services/api/uploadImage"
import moment from "moment"
import eventsApi from 'services/api/events'
//import uploadImageServiceApi from "../../services/api/uploadImage";

const EventForm = ({ onSubmit, initialValues }) => {
    const { state, setSubmittingForm } = useContext(EventContext)
    const [currentAddress,] = useState(initialValues?.location?.address || "")
    const [errors, setErrors] = useState({})
    const [popularTags, setPopularTags] = useState([])
    const [formData, setFormData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            title: initialValues?.title || '',
            description: initialValues?.description || '',
            link: initialValues?.link || '',
            images: initialValues?.images || ['http://'],
            date: initialValues?.date || '',
            expireAt: initialValues?.expireAt || '',
            active: initialValues?.active || false,
            location: initialValues?.location || {},
            virtual: initialValues?.virtual || false,
            tags: initialValues?.tags || [],
            time: initialValues?.time || '',
            verified: initialValues?.verified || false,
            eventType: initialValues?.eventType || 'Artible',
            dateTimeLink: initialValues?.dateTimeLink || ''
        }
    )

    const handleOnSubmit = async () => {
        let img = formData.images
        delete formData.location._id
        delete formData.location.createdAt
        delete formData.location.updatedAt

        setSubmittingForm(true)
        if (!Array.isArray(formData.images)) {
            let upload = await uploadImage()
            if (!upload.success) {
                return alert('failed to upload image')
            }
            img = [`${process.env.REACT_APP_SPACES_ENDPOINT}${upload.uid}/Events/${upload.imageName}`]
        }
        let data = { ...formData, images: img }
        delete formData.location.value
        if (data.eventType === 'Admin') {
            delete data.date
            delete data.time
            delete data.expireAt
        }
        let errors = await validateEventData(data)
        if (errors) {
            console.log(errors)
            setErrors(errors)
            setSubmittingForm(false)
        } else {
            onSubmit({ ...data, time_zone: getTimeZone() }, initialValues?._id)
        }
    }

    const handleChange = (event) => {
        if (['active', 'verified', 'virtual'].includes(event.target.name)) {
            setFormData({ [event.target.name]: !formData[event.target.name] })
        } else setFormData({ [event.target.name]: event.target.value })
    }

    const handleDateChange = (event) => {
        setFormData({ date: event })
    }

    const handleDateTimeChange = (event) => {
        setFormData({ expireAt: event })
    }
    const handleTimeChange = (event) => {
        setFormData({ time: event })
    }

    const selectImage = (event) => {
        setFormData({
            images: {
                currentFile: event.target.files[0],
                previewImage: URL.createObjectURL(event.target.files[0]),
                progress: 0,
                message: ""
            }
        })
    }

    const getAddressData = (value) => {
        geocodeByAddress(value.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) =>
                setFormData({
                    location: {
                        type: 'Point',
                        'coordinates': [lng, lat],
                        address: value.label,
                        value
                    }
                }),
            )
    }

    const uploadImage = async () => {
        setFormData({
            images: {
                ...formData.images,
                progress: 0
            }
        })

        let res = await uploadImageServiceApi.upload(formData.images.currentFile, (event) => {
            setFormData({
                images: {
                    ...formData.images,
                    progress: Math.round((100 * event.loaded) / event.total),
                }
            })
        })
        return res
    }

    useEffect(() => {
        eventsApi.getPopularTags().then((res) => {
            if (res?.success) {
                setPopularTags(res?.data)
            }
        })
    }, [])

    return (
        !state.submittingForm ?
            <Form>
                <Row>
                    <Col sm="8">
                        <Form.Group>
                            <Form.Row>
                                <Col>
                                    <p className="category">Active</p>
                                    <Form.Check
                                        data-cy="mc-active"
                                        name="active"
                                        type="switch"
                                        id="custom-switch-11"
                                        className="mb-1"
                                        style={{ display: 'inline-flex' }}
                                        checked={formData.active}
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col>
                                    <p className="category">Virtual</p>
                                    <Form.Check
                                        data-cy="mc-virtual"
                                        name="virtual"
                                        type="switch"
                                        id="custom-switch-12"
                                        className="mb-1"
                                        style={{ display: 'inline-flex' }}
                                        checked={formData.virtual}
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col>
                                    <p className="category">Verified</p>
                                    <Form.Check
                                        data-cy="mc-verified"
                                        name="verified"
                                        type="switch"
                                        id="custom-switch-13"
                                        className="mb-1"
                                        style={{ display: 'inline-flex' }}
                                        checked={formData.verified}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Form.Row>
                        </Form.Group>
                    </Col>
                    <Col sm="4">
                        <Form.Group>
                            <label>Type</label>
                            <Form.Control
                                disabled={initialValues}
                                name="eventType"
                                as="select" defaultValue="Artible"
                                onChange={handleChange}
                                value={formData?.eventType}
                                required>
                                <option value="Artible">Artible</option>
                                <option value="Admin">Admin</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col sm="3">
                        <Form.Group>
                            <label>Title</label>
                            <Form.Control
                                required
                                data-cy="title"
                                name="title"
                                onChange={handleChange}
                                placeholder="Enter Title"
                                value={formData.title ?? ''}
                                isInvalid={!!errors.title}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.title}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="3">
                        <Form.Group>
                            <label>Description</label>
                            <Form.Control
                                required
                                data-cy="description"
                                name="description"
                                onChange={handleChange}
                                placeholder="Enter Description"
                                value={formData.description ?? ''}
                                isInvalid={!!errors.description}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.description}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="3">
                        <Form.Group>
                            <label>Link</label>
                            <Form.Group>
                                <Form.Control
                                    data-cy="link"
                                    name="link"
                                    onChange={handleChange}
                                    placeholder="Enter Link: https://example.com"
                                    value={formData.link ?? ''}
                                    isInvalid={!!errors.link}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.link}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    {formData?.eventType === 'Admin' && <Col sm="3">
                        <Form.Group>
                            <label>Date Time Link</label>
                            <Form.Group>
                                <Form.Control
                                    data-cy="dateTimeLink"
                                    name="dateTimeLink"
                                    onChange={handleChange}
                                    placeholder="Enter Link: https://example.com"
                                    value={formData.dateTimeLink ?? ''}
                                    isInvalid={!!errors.dateTimeLink}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.dateTimeLink}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Group>
                    </Col>}
                    <Col sm="6">
                        <Form.Group>
                            <label>Image</label>
                            <Form.Group>
                                <Form.Control className="form-control" type="file" accept="image/*"
                                    onChange={selectImage}
                                    data-cy="image"
                                    name="image"
                                    isInvalid={!!errors.images}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.images}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col sm="6">
                        <label className="grey-text">Location</label>
                        <div style={{
                            pointerEvents: (!formData.virtual)
                                ? ''
                                : 'none',
                        }}>
                            <label className="grey-text">Current Location: {currentAddress}</label>
                            <GooglePlacesAutocomplete
                                apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
                                selectProps={{
                                    value: formData.location.value,
                                    onChange: value => getAddressData(value),
                                }}
                            />
                        </div>
                    </Col>
                    {formData?.eventType !== 'Admin' && <>
                        <Col md="4">
                            <label>Date</label>
                            <Form.Group>
                                <ReactDatetime
                                    value={moment(formData.date) || ""}
                                    onChange={handleDateChange}
                                    inputProps={{
                                        id: 'date',
                                        name: 'date',
                                        className: "form-control",
                                        placeholder: "Date Picker Here",
                                    }}
                                    timeFormat={false}
                                ></ReactDatetime>
                            </Form.Group>
                            {errors?.date && <div cypressdatacy="body-error" style={{
                                width: '100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545',
                            }}>{errors?.date}</div>}
                        </Col>
                        <Col md="4">
                            <label>Time</label>
                            <Form.Group>
                                <ReactDatetime
                                    value={moment(formData.time) || ""}
                                    onChange={handleTimeChange}
                                    dateFormat={false}
                                    inputProps={{
                                        id: 'time',
                                        name: 'time',
                                        className: "form-control",
                                        placeholder: "Time Picker Here"
                                    }}
                                ></ReactDatetime>
                            </Form.Group>
                            {errors?.time && <div cypressdatacy="body-error" style={{
                                width: '100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545',
                            }}>{errors?.time}</div>}
                        </Col>
                        <Col md="4">
                            <label>Expiration</label>
                            <Form.Group>
                                <ReactDatetime
                                    value={moment(formData.expireAt) || ""}
                                    onChange={handleDateTimeChange}
                                    inputProps={{
                                        id: 'expireAt',
                                        name: 'expireAt',
                                        className: "form-control",
                                        placeholder: "Datetime Picker Here",
                                    }}
                                ></ReactDatetime>
                            </Form.Group>
                            {errors?.expireAt && <div cypressdatacy="body-error" style={{
                                width: '100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545',
                            }}>{errors?.expireAt}</div>}
                        </Col>
                    </>}
                    <Col md="12">
                        <label>Tags</label>
                        <Form.Group>
                            <TagsInput
                                value={formData.tags}
                                onChange={(value) => setFormData({ tags: value })}
                                tagProps={{
                                    className: "react-tagsinput-tag tag-azure",
                                    id: 'tags',
                                    name: 'tags',
                                    'data-cy': 'tags'
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.tags}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md="12">
                        <ListGroup horizontal="sm" style={{ overflow: 'scroll' }}>
                            {popularTags.map((tag, idx) => {
                                return <ListGroup.Item key={idx} action style={{ display: 'inline-block', whiteSpace: 'nowrap' }} onClick={(event) => {
                                    event.preventDefault()
                                    if (!formData.tags.includes(tag)) {
                                        setFormData({ tags: [...formData.tags, tag] })
                                    }
                                }}>{tag}</ListGroup.Item>
                            })}
                        </ListGroup>
                    </Col>
                </Row>
                <Modal.Footer>
                    <Button variant="primary" className="submit-btn"
                        onClick={handleOnSubmit}
                        data-cy="submitEvent">
                        Submit
                    </Button>
                </Modal.Footer>
            </Form>
            : <Loading message="Submitting Form" showLogo={true} />
    )
}

export default EventForm
