import {
    BrowserRouter as Router, Redirect,
    Route,
    Switch
} from 'react-router-dom'
import Login from "pages/Login/Login";
import {AuthContextProvider, useAuthState} from 'utils/firebase'
import Admin from "./layouts/Admin";

const AuthenticatedRoute = ({component: C, ...props}) => {
    const {isAuthenticated} = useAuthState()
    console.log(`AuthenticatedRoute: ${isAuthenticated}`)
    return (
        <Route
            {...props}
            render={routeProps =>
                isAuthenticated ? <C {...routeProps} /> : <Redirect to="/"/>
            }
        />
    )
}

const UnauthenticatedRoute = ({component: C, ...props}) => {
    const {isAuthenticated} = useAuthState()
    console.log(`UnauthenticatedRoute: ${isAuthenticated}`)
    return (
        <Route
            {...props}
            render={routeProps =>
                !isAuthenticated ? <C {...routeProps} /> : <Redirect to="/admin/dashboard"/>
            }
        />
    )
}

function App() {
    return (
        <AuthContextProvider>
            <Router>
                <Switch>
                    <AuthenticatedRoute path="/admin" component={Admin}/>
                    <UnauthenticatedRoute path="/" component={Login}/>
                </Switch>
            </Router>
        </AuthContextProvider>
    )
}

export default App
