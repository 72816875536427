import React from "react";
import {Container} from "react-bootstrap";

const Dashboard = () => {
    return (
        <>
            <Container fluid className="pt-3">
                <h6>
                    <center>You are Authenticated</center>
                </h6>
            </Container>
        </>
    );
};

export default Dashboard;
