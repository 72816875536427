import * as yup from 'yup'

const requestSchema = yup.object().shape({
    city: yup.string()
        .matches(/^[A-Z ]+$/i, 'City is in the incorrect format')
        .max(50)
        .required('City can not be empty'),
    description: yup.string().required(),
    link: yup.string().when('type', (type, schema) => {
        return (type !== 'gps') ? schema.matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/, 'Link is in the incorrect format: Example: https://example.com') : schema
    }),
    location: yup.object().shape({
        type: yup.string(),
        coordinates: yup.array().min(2).max(2),
    }),
    title: yup.string()
        .matches(/^[a-zA-Z0-9 ]*$/, 'Title is in the incorrect format')
        .required('Title can not be empty'),
    type: yup.string()
        .matches(/(gps|link|deeplink)/, 'Type is in the incorrect format')
        .required('Type can not be empty'),
})

export const validateRequestData = async (data) => {
    try {
        await requestSchema.validate(data);
        return false
    } catch (error) {
        return {[error.path]: error.message}
    }
}
