import React, {useState} from "react";
import {Collapse, Nav} from "react-bootstrap";
import {getAuth} from "firebase/auth";
import {useHistory} from "react-router-dom";

export const ProfileInfo = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    let history = useHistory();

    // this is for the user collapse
    const [userCollapseState, setUserCollapseState] = useState(false);

    function signOut() {
        auth.signOut().then(() => {
            history.replace('/')
        })
    }

    return (
        <div className="user" data-cy="userOptions">
            <div className="photo">
                <img
                    alt="..."
                    src={require("assets/img/default-avatar.png").default}
                ></img>
            </div>
            <div className="info">
                <a
                    className={userCollapseState ? "collapsed" : ""}
                    data-toggle="collapse"
                    href="#account"
                    onClick={(e) => {
                        e.preventDefault();
                        setUserCollapseState(!userCollapseState);
                    }}
                    aria-expanded={userCollapseState}
                >
                    <span>
                        {`${user?.email.substring(0, 15)}...`} <b className="caret"></b>
                    </span>
                </a>
                <Collapse id="collapseExample" in={userCollapseState}>
                    <div>
                        <Nav as="ul">
                            <li>
                                <a
                                    data-cy="logout"
                                    className="profile-dropdown"
                                    href="#logout"
                                    onClick={signOut}
                                >
                                    <span className="sidebar-mini">LG</span>
                                    <span className="sidebar-normal">Logout</span>
                                </a>
                            </li>
                        </Nav>
                    </div>
                </Collapse>
            </div>
        </div>
    );
};
