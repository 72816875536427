import {createDataContext} from '../../context/createDataContext'
import eventsApi from '../../services/api/events'
import {conformTableRowData} from '../../services/utilities/conformTableRowData'
import moment from "moment";

const initialEventsState = {
    data: [],
    nextPage: '',
    prevPage: '',
    tableColumns: [
        {
            Header: 'Title',
            accessor: 'title',
        },
        {
            Header: 'Description',
            accessor: 'description',
        },
        {
            Header: 'Link',
            accessor: 'link',
        },
        {
            Header: 'Image',
            accessor: row => {
                return <img src={`${row.images[0]}`} height={250} width={250}/>;
            },
        },
        {
            Header: 'Date',
            accessor: row => {
                return moment(row.date).format("LL");
            },
        },
        {
            Header: 'Time',
            accessor: row => {
                return moment(row.time).format("LTS");
            },
        },
        {
            Header: 'Location',
            accessor: 'location.address',
        },
        {
            Header: 'Tags',
            accessor: row => {
                return row.tags.join(',')
            },
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            sortable: false,
            filterable: false,
        },
    ],
    alert: null,
    submittingForm: false,
    loading: false,
    searchValues: {
        companyName: '',
        description: '',
        link: '',
        image: ''
    },
    formErrors: {}
}

const eventReducer = (state, action) => {
    switch (action.type) {
        case 'get_events':
            return {...state, ...action.payload}
        case 'create_event':
            return {...state, ...action.payload}
        case 'update_event':
            return {...state, ...action.payload}
        case 'delete_event':
            return {...state, ...action.payload}
        case 'set_search_input_values':
            return {...state, ...action.payload}
        case 'set_loading':
            return {...state, ...action.payload}
        case 'set_alert':
            return {...state, ...action.payload}
        case 'set_submitting_form':
            return {...state, ...action.payload}
        case 'reset':
            return initialEventsState
        default:
            return state
    }
}

const getEvents = (dispatch) => {
    return async (filter, edit, del) => {
        const response = await eventsApi.getAllEventsNoPagination(filter)
        let eventsData = conformTableRowData(response.data, 'editEvent',
            'deleteEvent', edit, del)
        dispatch({
            type: 'get_events',
            payload: {
                data: eventsData,
                loading: false,
                nextPage: `?${response.nextPageUrl?.split('?')[1]}`,
                prevPage: `?${response.prevPageUrl?.split('?')[1]}`
            },
        })
    }
}

const getData = (dispatch) => {
    return async (filter, edit, del, oldData, next) => {
        const response = await eventsApi.getAllEvents(filter)
        let eventsData = conformTableRowData(response.data, 'editEvent',
            'deleteEvent', edit, del)
        dispatch({
            type: 'get_events',
            payload: {
                data: next ? [...oldData, ...eventsData] : eventsData,
                loading: false,
                nextPage: `?${response.nextPageUrl.split('?')[1]}`,
                prevPage: `?${response.prevPageUrl.split('?')[1]}`
            },
        })
    }
}

const setSearchValues = (dispatch) => {
    return async (searchValues) => {
        dispatch({
            type: 'set_search_input_values',
            payload: {searchValues: searchValues},
        })
    }
}

const setLoading = (dispatch) => {
    return async (value) => {
        dispatch({type: 'set_loading', payload: {loading: value}})
    }
}

const setSubmittingForm = (dispatch) => {
    return async (value) => {
        dispatch({type: 'set_submitting_form', payload: {submittingForm: value}})
    }
}

const setAlert = (dispatch) => {
    return async (alert) => {
        dispatch({type: 'set_alert', payload: {alert: alert}})
    }
}

const updateEvent = (dispatch) => {
    return async (data, id, callback) => {
        //set the modal to loading so the form can not get submitted twice
        dispatch({type: 'set_submitting_form', payload: {submittingForm: true}})
        eventsApi.updateEvent(data, id).then((res) => {
            dispatch({
                type: 'update_event', payload: {
                    submittingForm: false,
                },
            })
            return (res.errors || res.isAxiosError) ? callback(false,
                'Failed to update Event.') : callback(
                true, 'Event has been successfully updated.')
        })
    }
}

const createEvent = (dispatch) => {
    return async (data, callback) => {
        dispatch({type: 'set_submitting_form', payload: {submittingForm: true}})
        eventsApi.createEvent(data).then((res) => {
            dispatch({
                type: 'create_event', payload: {
                    submittingForm: false,
                },
            })
            return (res.errors || res.isAxiosError) ? callback(false,
                'Failed to create Event.') : callback(
                true, 'Event has been successfully added.')
        })
    }
}

const deleteEvent = (dispatch) => {
    return async (data, callback) => {
        eventsApi.deleteEvent(data._id).then((res) => {
            dispatch({
                type: 'delete_event', payload: {
                    submittingForm: false,
                },
            })
            return (res.errors || res.isAxiosError) ? callback(false,
                'Failed to delete Event.') : callback(
                true, 'Event has been successfully deleted.')
        })
    }
}

const deletePastEvents = (dispatch) => {
    return async (callback) => {
        eventsApi.deletePastEvents().then((res) => {
            dispatch({
                type: 'delete_event', payload: {
                    submittingForm: false,
                },
            })
            return (res.errors || res.isAxiosError) ? callback(false,
                'Failed to delete Events.') : callback(
                true, 'Past Events has been successfully deleted.')
        })
    }
}

export const {Context, Provider} = createDataContext(eventReducer, {
    getEvents,
    getData,
    setSearchValues,
    setSubmittingForm,
    setLoading,
    setAlert,
    createEvent,
    updateEvent,
    deleteEvent,
    deletePastEvents
}, initialEventsState)

