import React from "react";
import {Provider as RequestProvider} from "./RequestContext";
import Requests from "./Requests";
import {ModalProvider} from "../../components/Modal/ModalContext";

const RequestsIndex = () => {
    return (
        <RequestProvider>
            <ModalProvider>
                <Requests/>
            </ModalProvider>
        </RequestProvider>
    );
};

export default RequestsIndex;
