import React, {useState} from "react";
import {
    Button,
    Navbar,
    Container
} from "react-bootstrap";

const AdminNavbar = () => {
    const [collapseOpen, setCollapseOpen] = useState(false);

    return (
        <>
            <Navbar expand="lg">
                <Container fluid>
                    <div className="navbar-wrapper">
                        <div className="navbar-minimize">
                            <Button
                                className="btn-fill btn-round btn-icon d-none d-lg-block bg-dark border-dark"
                                variant="dark"
                                onClick={() => document.body.classList.toggle("sidebar-mini")}
                            >
                                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"/>
                                <i className="fas fa-bars visible-on-sidebar-mini"/>
                            </Button>
                            <Button
                                className="btn-fill btn-round btn-icon d-block d-lg-none bg-dark border-dark"
                                variant="dark"
                                onClick={() =>
                                    document.documentElement.classList.toggle("nav-open")
                                }
                            >
                                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"/>
                                <i className="fas fa-bars visible-on-sidebar-mini"/>
                            </Button>
                        </div>
                    </div>
                    <button
                        className="navbar-toggler navbar-toggler-right border-0"
                        type="button"
                        onClick={() => setCollapseOpen(!collapseOpen)}>
                        <span className="navbar-toggler-bar burger-lines"/>
                        <span className="navbar-toggler-bar burger-lines"/>
                        <span className="navbar-toggler-bar burger-lines"/>
                    </button>
                    <Navbar.Collapse className="justify-content-end" in={collapseOpen}>

                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default AdminNavbar;
