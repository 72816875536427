import {ApiCore} from "./utilities";

const requestsApi = new ApiCore({
    get: true,
    post: true,
    put: true,
    del: true
});

// Add custom api calls
requestsApi.getAllRequests = () => requestsApi.get(`/request`);

requestsApi.acceptRequest = (id,request) => requestsApi.post(`/request/accept/${id}`, request);

requestsApi.deleteRequest = (id) => requestsApi.del(`/request/${id}`);

export default requestsApi;
