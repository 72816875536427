import { Button } from 'react-bootstrap'

export const conformTableRowData = (
  data, editButtonId, deleteButtonId, edit, del, accept) => {
  return data.map((prop, key) => {
    return {
      ...prop,
      actions: (
        <div className="actions-right">
          {edit &&
            <Button
              data-cy={`${editButtonId}${key}`}
              onClick={() => edit(prop)}
              variant="warning"
              size="sm"
              className="text-warning btn-link edit"
            >
              <i className="fa fa-edit" />
            </Button>
          }
          {accept &&
            <Button
              data-cy={`${accept}${key}`}
              onClick={() => accept(prop)}
              variant="danger"
              size="sm"
              className="text-success btn-link"
            >
              <i className="fa fa-check" />
            </Button>
          }
          {del &&
            <Button
              data-cy={`${deleteButtonId}${key}`}
              onClick={() => del(prop)}
              variant="danger"
              size="sm"
              className="btn-link remove text-danger"
            >
              <i className="fa fa-trash" />
            </Button>
          }
        </div>
      ),
    }
  })
}
