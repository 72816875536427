import {createDataContext} from '../../context/createDataContext'
import cityOffersApi from '../../services/api/cityOffers'
import {conformTableRowData} from '../../services/utilities/conformTableRowData'

const initialCityOffersState = {
    data: [],
    tableColumns: [
        {
            Header: 'City',
            accessor: 'city',
        },
        {
            Header: 'Description',
            accessor: 'description',
        },
        {
            Header: 'Link',
            accessor: 'link',
        },
        {
            Header: 'Title',
            accessor: 'title',
        },
        {
            Header: 'Type',
            accessor: 'type',
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            sortable: false,
            filterable: false,
        },
    ],
    alert: null,
    submittingForm: false,
    loading: false,
    searchValues: {
        city: '',
        description: '',
        link: '',
        title: '',
        type: '',
    },
    formErrors: {}
}

const cityOfferReducer = (state, action) => {
    switch (action.type) {
        case 'get_city_offers':
            return {...state, ...action.payload}
        case 'create_city_offer':
            return {...state, ...action.payload}
        case 'update_city_offer':
            return {...state, ...action.payload}
        case 'delete_city_offer':
            return {...state, ...action.payload}
        case 'set_search_input_values':
            return {...state, ...action.payload}
        case 'set_loading':
            return {...state, ...action.payload}
        case 'set_alert':
            return {...state, ...action.payload}
        case 'set_submitting_form':
            return {...state, ...action.payload}
        case 'reset':
            return initialCityOffersState
        default:
            return state
    }
}

const getCityOffers = (dispatch) => {
    return async (filter, edit, del) => {
        const response = await cityOffersApi.getAllCityOffers(filter)
        let cityOffersData = conformTableRowData(response.data, 'editCityOffer',
            'deleteCityOffer', edit, del)
        dispatch({
            type: 'get_city_offers',
            payload: {data: cityOffersData, loading: false},
        })
    }
}

const setSearchValues = (dispatch) => {
    return async (searchValues) => {
        dispatch({
            type: 'set_search_input_values',
            payload: {searchValues: searchValues},
        })
    }
}

const setLoading = (dispatch) => {
    return async (value) => {
        dispatch({type: 'set_loading', payload: {loading: value}})
    }
}

const setAlert = (dispatch) => {
    return async (alert) => {
        dispatch({type: 'set_alert', payload: {alert: alert}})
    }
}

const updateCityOffer = (dispatch) => {
    return async (data, id, callback) => {
        //set the modal to loading so the form can not get submitted twice
        dispatch({type: 'set_submitting_form', payload: {submittingForm: true}})
        cityOffersApi.updateCityOffer(data, id).then((res) => {
            dispatch({
                type: 'update_city_offer', payload: {
                    submittingForm: false,
                },
            })
            return (res.errors || res.isAxiosError) ? callback(false,
                'Failed to update City Offer.') : callback(
                true, 'City Offer has been successfully updated.')
        })
    }
}

const createCityOffer = (dispatch) => {
    return async (data, callback) => {
        dispatch({type: 'set_submitting_form', payload: {submittingForm: true}})
        cityOffersApi.createCityOffer(data).then((res) => {
            dispatch({
                type: 'create_city_offer', payload: {
                    submittingForm: false,
                },
            })
            return (res.errors || res.isAxiosError) ? callback(false,
                'Failed to create City Offer.') : callback(
                true, 'City Offer has been successfully added.')
        })
    }
}

const deleteCityOffer = (dispatch) => {
    return async (data, callback) => {
        cityOffersApi.deleteCityOffer(data.id).then((res) => {
            dispatch({
                type: 'delete_city_offer', payload: {
                    submittingForm: false,
                },
            })
            return (res.errors || res.isAxiosError) ? callback(false,
                'Failed to delete City Offer.') : callback(
                true, 'City Offer has been successfully deleted.')
        })
    }
}

export const {Context, Provider} = createDataContext(cityOfferReducer, {
    getCityOffers,
    setSearchValues,
    setLoading,
    setAlert,
    createCityOffer,
    updateCityOffer,
    deleteCityOffer,
}, initialCityOffersState)

