import {ApiCore} from "./utilities";

const cityOffersApi = new ApiCore({
    get: true,
    post: true,
    put: true,
    del: true
});

// Add custom api calls
cityOffersApi.getAllCityOffers = (filter = '') => cityOffersApi.get(`/cityOffer${filter}`);

cityOffersApi.getCityOffer = (id) => cityOffersApi.get(`/cityOffer/${id}`);

cityOffersApi.deleteCityOffer = (id) => cityOffersApi.del(`/cityOffer/${id}`);

cityOffersApi.updateCityOffer = (cityOffer, id) => cityOffersApi.put(`/cityOffer/${id}`, cityOffer);

cityOffersApi.createCityOffer = (cityOffer) => cityOffersApi.post(`/cityOffer`, cityOffer);

export default cityOffersApi;
