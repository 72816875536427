import './splash-screen.css';

const Loading = ({message, showLogo = true}) => {
    return (
        <div className="splash-screen pt-3">
            {
                showLogo &&
                <div>
                    <img
                        src={process.env.PUBLIC_URL + '/Logo transparent copy.png'}
                        alt="..."
                    />
                </div>
            }
            <div>{message}</div>
            <div className="loading-dot">.</div>
        </div>
    );
};
export default Loading;
