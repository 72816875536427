import * as yup from 'yup'

const adSchema = yup.object().shape({
    companyName: yup.string()
        .matches(/^[a-zA-Z0-9,.' -&:]*$/i, 'Company Name is in the incorrect format')
        .max(50)
        .required('Company Name can not be empty'),
    description: yup.string()
        .matches(/^[a-zA-Z0-9 ]*$/, 'Description is in the incorrect format')
        .max(1000)
        .required(),
    link: yup.string().matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/, 'Link is in the incorrect format: Example: https://example.com').required('Link can not be empty'),
    image: yup.string()
        .required('Image can not be empty')
})

export const validateAdData = async (data) => {
    try {
        await adSchema.validate(data);
        return false
    } catch (error) {
        return {[error.path]: error.message}
    }
}
