import { createDataContext } from '../../context/createDataContext'
import requestsApi from '../../services/api/requests'
import { conformTableRowData } from '../../services/utilities/conformTableRowData'

const initialRequestsState = {
    data: [],
    tableColumns: [
        {
            Header: 'Requested Number',
            accessor: 'phoneNumber',
        },
        {
            Header: 'Requested Date',
            accessor: 'createdAt',
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            sortable: false,
            filterable: false,
        },
    ],
    alert: null,
    submittingForm: false,
    loading: false,
    searchValues: {
        phoneNumber: ''
    },
    formErrors: {}
}

const requestReducer = (state, action) => {
    switch (action.type) {
        case 'get_requests':
            return { ...state, ...action.payload }
        case 'create_request':
            return { ...state, ...action.payload }
        case 'accept_request':
            return { ...state, ...action.payload }
        case 'delete_request':
            return { ...state, ...action.payload }
        case 'set_search_input_values':
            return { ...state, ...action.payload }
        case 'set_loading':
            return { ...state, ...action.payload }
        case 'set_alert':
            return { ...state, ...action.payload }
        case 'set_submitting_form':
            return { ...state, ...action.payload }
        case 'reset':
            return initialRequestsState
        default:
            return state
    }
}

const getRequests = (dispatch) => {
    return async (filter, edit, del, accept) => {
        const response = await requestsApi.getAllRequests(filter)
        let requestsData = conformTableRowData(response.data, 'editRequest',
            'deleteRequest', edit, del, accept)
        dispatch({
            type: 'get_requests',
            payload: { data: requestsData, loading: false },
        })
    }
}

const setSearchValues = (dispatch) => {
    return async (searchValues) => {
        dispatch({
            type: 'set_search_input_values',
            payload: { searchValues: searchValues },
        })
    }
}

const setLoading = (dispatch) => {
    return async (value) => {
        dispatch({ type: 'set_loading', payload: { loading: value } })
    }
}

const setAlert = (dispatch) => {
    return async (alert) => {
        dispatch({ type: 'set_alert', payload: { alert: alert } })
    }
}

const createRequest = (dispatch) => {
    return async (data, callback) => {
        dispatch({ type: 'set_submitting_form', payload: { submittingForm: true } })
        requestsApi.createRequest(data).then((res) => {
            dispatch({
                type: 'create_request', payload: {
                    submittingForm: false,
                },
            })
            return (res.errors || res.isAxiosError) ? callback(false,
                'Failed to create City Offer.') : callback(
                    true, 'City Offer has been successfully added.')
        })
    }
}

const acceptRequest = (dispatch) => {
    return async (id, data, callback) => {
        dispatch({ type: 'set_submitting_form', payload: { submittingForm: true } })
        requestsApi.acceptRequest(id, data).then((res) => {
            dispatch({
                type: 'accept_request', payload: {
                    submittingForm: false,
                },
            })
            return (res.errors || res.isAxiosError) ? callback(false,
                'Failed to accept Request.') : callback(
                    true, 'Request has been successfully accepted.')
        })
    }
}

const deleteRequest = (dispatch) => {
    return async (data, callback) => {
        requestsApi.deleteRequest(data.id).then((res) => {
            dispatch({
                type: 'delete_request', payload: {
                    submittingForm: false,
                },
            })
            return (res.errors || res.isAxiosError) ? callback(false,
                'Failed to delete Request.') : callback(
                    true, 'Request has been successfully deleted.')
        })
    }
}


export const { Context, Provider } = createDataContext(requestReducer, {
    getRequests,
    setSearchValues,
    setLoading,
    setAlert,
    createRequest,
    acceptRequest,
    deleteRequest
}, initialRequestsState)

