import {ApiCore} from "./utilities";

const uploadImageServiceApi = new ApiCore({
    get: false,
    post: true,
    put: false,
    del: false
});

// Add custom api calls
uploadImageServiceApi.upload = async (file, onUploadProgress) => {
    let formData = new FormData();

    formData.append('Events', 'admin')

    formData.append("upload", file);

    return uploadImageServiceApi.post("/upload", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
    });
};

export default uploadImageServiceApi;
