import {createDataContext} from '../../context/createDataContext'
import adsApi from '../../services/api/ads'
import {conformTableRowData} from '../../services/utilities/conformTableRowData'

const initialAdsState = {
    data: [],
    tableColumns: [
        {
            Header: 'Company Name',
            accessor: 'companyName',
        },
        {
            Header: 'Description',
            accessor: 'description',
        },
        {
            Header: 'Link',
            accessor: 'link',
        },
        {
            Header: 'Image',
            accessor: row => {
                return <img src={`${row.image}`} height={250} width={250}/>;
            },
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            sortable: false,
            filterable: false,
        },
    ],
    alert: null,
    submittingForm: false,
    loading: false,
    searchValues: {
        companyName: '',
        description: '',
        link: '',
        image: ''
    },
    formErrors: {}
}

const adReducer = (state, action) => {
    switch (action.type) {
        case 'get_ads':
            return {...state, ...action.payload}
        case 'create_ad':
            return {...state, ...action.payload}
        case 'update_ad':
            return {...state, ...action.payload}
        case 'delete_ad':
            return {...state, ...action.payload}
        case 'set_search_input_values':
            return {...state, ...action.payload}
        case 'set_loading':
            return {...state, ...action.payload}
        case 'set_alert':
            return {...state, ...action.payload}
        case 'set_submitting_form':
            return {...state, ...action.payload}
        case 'reset':
            return initialAdsState
        default:
            return state
    }
}

const getAds = (dispatch) => {
    return async (filter, edit, del) => {
        const response = await adsApi.getAllAds(filter)
        let adsData = conformTableRowData(response.data, 'editAd',
            'deleteAd', edit, del)
        dispatch({
            type: 'get_ads',
            payload: {data: adsData, loading: false},
        })
    }
}

const setSearchValues = (dispatch) => {
    return async (searchValues) => {
        dispatch({
            type: 'set_search_input_values',
            payload: {searchValues: searchValues},
        })
    }
}

const setSubmittingForm = (dispatch) => {
    return async (value) => {
        dispatch({type: 'set_submitting_form', payload: {submittingForm: value}})
    }
}

const setLoading = (dispatch) => {
    return async (value) => {
        dispatch({type: 'set_loading', payload: {loading: value}})
    }
}

const setAlert = (dispatch) => {
    return async (alert) => {
        dispatch({type: 'set_alert', payload: {alert: alert}})
    }
}

const updateAd = (dispatch) => {
    return async (data, id, callback) => {
        //set the modal to loading so the form can not get submitted twice
        dispatch({type: 'set_submitting_form', payload: {submittingForm: true}})
        adsApi.updateAd(data, id).then((res) => {
            dispatch({
                type: 'update_ad', payload: {
                    submittingForm: false,
                },
            })
            return (res.errors || res.isAxiosError) ? callback(false,
                'Failed to update Ad.') : callback(
                true, 'Ad has been successfully updated.')
        })
    }
}

const createAd = (dispatch) => {
    return async (data, callback) => {
        dispatch({type: 'set_submitting_form', payload: {submittingForm: true}})
        adsApi.createAd(data).then((res) => {
            dispatch({
                type: 'create_ad', payload: {
                    submittingForm: false,
                },
            })
            return (res.errors || res.isAxiosError) ? callback(false,
                'Failed to create Ad.') : callback(
                true, 'Ad has been successfully added.')
        })
    }
}

const deleteAd = (dispatch) => {
    return async (data, callback) => {
        adsApi.deleteAd(data.id).then((res) => {
            dispatch({
                type: 'delete_ad', payload: {
                    submittingForm: false,
                },
            })
            return (res.errors || res.isAxiosError) ? callback(false,
                'Failed to delete Ad.') : callback(
                true, 'Ad has been successfully deleted.')
        })
    }
}

export const {Context, Provider} = createDataContext(adReducer, {
    getAds,
    setSearchValues,
    setSubmittingForm,
    setLoading,
    setAlert,
    createAd,
    updateAd,
    deleteAd,
}, initialAdsState)

