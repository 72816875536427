import React from "react";
import {Provider as EventProvider} from "./EventContext";
import Events from "./Events";
import {ModalProvider} from "../../components/Modal/ModalContext";

const EventsIndex = () => {
    return (
        <EventProvider>
            <ModalProvider>
                <Events/>
            </ModalProvider>
        </EventProvider>
    );
};

export default EventsIndex;
