import React, {createContext, useCallback, useContext, useEffect, useState} from 'react'
import {Modal as BSModal} from 'react-bootstrap';

const ModalContext = createContext()

const Modal = ({modal, unSetModal}) => {
    useEffect(() => {
        const bind = e => {
            if (e.keyCode !== 27) {
                return
            }

            if (document.activeElement && ['INPUT', 'SELECT'].includes(document.activeElement.tagName)) {
                return
            }

            unSetModal()
        }

        document.addEventListener('keyup', bind)
        return () => document.removeEventListener('keyup', bind)
    }, [modal, unSetModal])

    return (
        <BSModal
            size="xl"
            className="modal-primary text-left"
            onHide={unSetModal}
            show={modal.show}
        >
            <BSModal.Header className="justify-content-center" closeButton>
                <h3>{modal.title}</h3>
            </BSModal.Header>
            <BSModal.Body className="text-center">
                {modal.content}
            </BSModal.Body>
        </BSModal>
    )
}

const ModalProvider = props => {
    const [modal, setModal] = useState({title: '', content: '', show: false})
    const unSetModal = useCallback(() => {
        setModal({title: '', content: '', show: false})
    }, [setModal])

    return (
        <ModalContext.Provider value={{unSetModal, setModal}} {...props} >
            {props.children}
            {modal.show && <Modal modal={{...modal}} unSetModal={unSetModal}/>}
        </ModalContext.Provider>
    )
}

const useModal = () => {
    const context = useContext(ModalContext)
    if (context === undefined) {
        throw new Error('useModal must be used within a UserProvider')
    }

    return context
}

export {ModalProvider, useModal}
