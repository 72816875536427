import moment from "moment-timezone";

export const createFilterStringFromSearchValues = (searchValues) => {
    let filter = "?";
    for (var key in searchValues) {
        if (searchValues[key]) {
            filter += `${key}=${searchValues[key]}&`;
        }
    }
    return filter.substring(0, filter.length - 1);
};

export const getTimeZone = () => {
    let timeZone = moment.tz.guess();
    let time = new Date();
    let timeZoneOffset = time.getTimezoneOffset();
    return moment.tz.zone(timeZone).abbr(timeZoneOffset);
}
