import React from "react";
import {Provider as CityOfferProvider} from "./CityOfferContext";
import CityOffers from "./CityOffers";
import {ModalProvider} from "../../components/Modal/ModalContext";

const CityOffersIndex = () => {
    return (
        <CityOfferProvider>
            <ModalProvider>
                <CityOffers/>
            </ModalProvider>
        </CityOfferProvider>
    );
};

export default CityOffersIndex;
