import React from "react";
import {
    Container,
} from "react-bootstrap";

const AdminFooter = () => {
    return (
        <>
            <footer className="footer">
                <Container fluid className="pl-4 ml-2">
                    <nav>
                        <p className="copyright text-center">
                            © <script>document.write(new Date().getFullYear())</script>
                            <a href="/">Artible City</a>
                        </p>
                    </nav>
                </Container>
            </footer>
        </>
    );
};

export default AdminFooter;
