import React, { useEffect, useContext } from 'react'
import ReactTable from '../../components/ReactTable/ApiSearchReactTable'
import { MainContent } from 'components/Main/MainContent'
import {
  SuccessAlert,
  WarningAlert,
  DangerAlert,
} from 'components/Alerts/Alerts'
import { createFilterStringFromSearchValues } from '../Pages.utils'
import { Context as RequestContext } from './RequestContext'
import { useModal } from '../../components/Modal/ModalContext'
import RequestForm from './RequestForm'

const Requests = () => {
  const {
    state,
    getRequests,
    setLoading,
    setAlert,
    createRequest,
    acceptRequest,
    deleteRequest
  } = useContext(RequestContext)
  const { setModal, unSetModal } = useModal()

  const add = () => {
    setModal({
      title: 'Add Request', content: <RequestForm onSubmit={(data) => {
        createRequest(data, errorHandler)
      }} />, show: true,
    })
  }

  const errorHandler = (success, message) => {
    unSetModal()
    if (!success) {
      return setAlert(<DangerAlert title="Failure!"
        onConfirm={hideAlert}
        onCancel={hideAlert}
        message={message} />)
    }
    getAllRequests()
    setAlert(<SuccessAlert title="Success!" onConfirm={hideAlert}
      onCancel={hideAlert}
      message={message} />,
    )
  }

  const getAllRequests = () => {
    setLoading(true)
    getRequests(createFilterStringFromSearchValues(state.searchValues), null,
      warningWithConfirmMessage, warningAcceptWithConfirmMessage)
  }

  const hideAlert = () => {
    setAlert(null)
  }


  const warningWithConfirmMessage = (data) => {
    setAlert(
      <WarningAlert onConfirm={() => deleteRequest(data, errorHandler)}
        onCancel={hideAlert}
        message="You will not be able to recover this Request!" />,
    )
  }

  const warningAcceptWithConfirmMessage = (data) => {
    setAlert(
      <WarningAlert onConfirm={() => acceptRequest(data._id, { phoneNumber: data.phoneNumber }, errorHandler)} warningMessage="Accept"
        onCancel={hideAlert}
        message={`Accept ${data.phoneNumber} request to access Artible City.`} />,
    )
  }

  // eslint-disable-next-line
  useEffect(getAllRequests, [state.searchValues])

  return (
    <>
      {state.alert}
      <MainContent title="Requests" addButtonId="addRequest"
        context={RequestContext}>
        <ReactTable
          data-cy="requestTable"
          context={RequestContext}
        />
      </MainContent>
    </>
  )
}

export default Requests
