import * as yup from 'yup'

const eventSchema = yup.object().shape({
    eventType: yup.string().oneOf(['Admin', 'Artible']),
    description: yup.string()
        .required(),
    link: yup.string().matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/, 'Link is in the incorrect format: Example: https://example.com').required('Link can not be empty'),
    dateTimeLink: yup.string().matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/, 'Date Time Link is in the incorrect format: Example: https://example.com'),
    images: yup.array()
        .required('Image can not be empty'),
    title: yup.string().matches(/^[a-zA-Z0-9 !&?,.\-']+$/, 'Title is in the incorrect format').required('Title can not be empty'),
    date: yup.mixed().when('eventType', {
        is: 'Artible',
        then: yup.string().required('Date can not be empty'),
    }),
    expireAt: yup.mixed().when('eventType', {
        is: 'Artible',
        then: yup.date().required('ExpireAt can not be empty'),
    }),
    active: yup.boolean().required('Active can not be empty'),
    location: yup.object().shape({
        type: yup.string(),
        coordinates: yup.array().min(2).max(2),
    }),
    virtual: yup.boolean().required('Virtual can not be empty'),
    tags: yup.array().of(yup.string().matches('', 'Tags is in the incorrect format')).min(1).max(10).required('Tags can not be empty'),
    time: yup.mixed().when('eventType', {
        is: 'Artible',
        then: yup.string().required('Time can not be empty'),
    }),
    verified: yup.boolean().required('Verified can not be empty')
})

export const validateEventData = async (data) => {
    try {
        await eventSchema.validate(data, { abortEarly: false })
        return false
    } catch (error) {
        return error.inner.reduce(
            (errors, err) => ({
                ...errors,
                [err.path]: err.message,
            }),
            {},
        )
    }
}
