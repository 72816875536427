import {Button, Col, Form, Modal, Row} from 'react-bootstrap';
import React, {useContext, useState} from 'react';
import {Context as RequestContext} from './RequestContext';
import Loading from '../../components/Loading/Loading';
import {validateRequestData} from './requests.validation';
import GooglePlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-google-places-autocomplete';

const RequestForm = ({onSubmit, initialValues}) => {
    const {state} = useContext(RequestContext);
    const [currentAddress,] = useState(initialValues?.location.address || "")
    const [city, setCity] = useState(initialValues?.city || '');
    const [description, setDescription] = useState(
        initialValues?.description || '');
    const [link, setLink] = useState(initialValues?.link || '');
    const [title, setTitle] = useState(initialValues?.title || '');
    const [type, setType] = useState(initialValues?.type || '');
    const [location, setLocation] = useState(initialValues?.location || {});
    const [errors, setErrors] = useState({});

    const getAddressData = (value) => {
        geocodeByAddress(value.label)
            .then(results => getLatLng(results[0]))
            .then(({lat, lng}) =>
                setLocation({
                    type: 'Point',
                    'coordinates': [lng, lat],
                    address: value.label,
                    value,
                }),
            );
    };

    const handleOnSubmit = async () => {
        let data = {};
        // if (type === 'gps') {
        delete location._id;
        delete location.createdAt;
        delete location.updatedAt;
        data = {city, description, link, title, type, location}
        // }
        // else {
        //     data = {city, description, link, title, type}
        // }
        let errors = await validateRequestData(
            data);
        if (errors) {
            setErrors(errors);
        } else {
            delete location.value;
            onSubmit(data,
                initialValues?.id);
        }
    };

    const handleChange = (event) => {
        switch (event.target.name) {
            case 'city':
                return setCity(event.target.value);
            case 'description':
                return setDescription(event.target.value);
            case 'link':
                return setLink(event.target.value);
            case 'title':
                return setTitle(event.target.value);
            default:
                return false
        }
    };

    const handleTypeChange = (event) => {
        setLink('');
        setLocation({});
        setType(event.target.value);
    };

    return (
        !state.submittingForm ?
            <Form>
                <Row>
                    <Col sm="4">
                        <Form.Group>
                            <label>Title</label>
                            <Form.Control
                                required
                                data-cy="title"
                                name="title"
                                onChange={handleChange}
                                placeholder="Enter Title"
                                value={title ?? ''}
                                isInvalid={!!errors.title}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.title}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="4">
                        <Form.Group>
                            <label>Description</label>
                            <Form.Control
                                required
                                data-cy="description"
                                name="description"
                                onChange={handleChange}
                                placeholder="Enter Description"
                                value={description ?? ''}
                                isInvalid={!!errors.description}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.description}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="4">
                        <label>City</label>
                        <Form.Group>
                            <Form.Control
                                required
                                data-cy="city"
                                name="city"
                                onChange={handleChange}
                                placeholder="Enter City"
                                value={city}
                                isInvalid={!!errors.city}/>
                            <Form.Control.Feedback type="invalid">
                                {errors.city}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="6">
                        <Form.Group>
                            <label>Type</label>
                            <Form.Control
                                disabled={initialValues}
                                name="type"
                                as="select" defaultValue={type}
                                onChange={handleTypeChange}
                                required>
                                <option value="">Please select City Offer Type</option>
                                <option value="gps">GPS</option>
                                <option value="link">Link</option>
                                <option value="deeplink">Deep Link</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>

                    <Col sm="6">
                        <label className="grey-text">Address</label>

                        {/*<div style={{*/}
                        {/*    pointerEvents: (type === 'gps')*/}
                        {/*        ? ''*/}
                        {/*        : 'none',*/}
                        {/*}}>*/}
                        {initialValues && <label className="grey-text">Current Location: {currentAddress}</label>}
                        <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
                            selectProps={{
                                value: location.value,
                                onChange: value => getAddressData(value),
                            }}
                        />
                        {/*</div>*/}
                        <label>Link</label>
                        <Form.Group>
                            <Form.Control
                                // disabled={(type === '' || type === 'gps')}
                                data-cy="link"
                                name="link"
                                onChange={handleChange}
                                placeholder="Enter Link: https://example.com"
                                value={link ?? ''}
                                isInvalid={!!errors.link}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.link}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                </Row>
                <Modal.Footer>
                    <Button variant="primary" className="submit-btn"
                            onClick={handleOnSubmit}
                            data-cy="submitRequest">
                        Submit
                    </Button>
                </Modal.Footer>
            </Form>
            : <Loading message="Submitting Form" showLogo={true}/>
    );
};

export default RequestForm;
