import React, {useState} from "react";

import {
    Button,
    Card,
    Form,
    Container,
    Col, Alert,
} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import Loading from "../../components/Loading/Loading";

function LoginPage() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState({failure: false, message: ''});
    const handleSubmit = async e => {
        e.preventDefault()
        setLoading(true);
        const {email, password} = e.target.elements
        const auth = getAuth()
        try {
            await signInWithEmailAndPassword(auth, email.value, password.value)
            history.push('/admin/dashboard')
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setLoginError({failure: true, message: e.message});
            setTimeout(() => {
                setLoginError({failure: false, message: ''});
            }, 3000)
        }
    }

    return (
        <>
            {
                !loading ?
                    <div
                        className="full-page section-image"
                        data-color="black"
                        data-image={require("assets/img/shutterstock_898276.jpg").default}
                    >
                        <div className="content d-flex align-items-center p-0">
                            <Container>
                                <Col className="mx-auto" lg="4" md="8">
                                    <Form className="form" onSubmit={handleSubmit}>
                                        <Card className={"card-login"}>
                                            <Card.Header>
                                                <Alert variant="danger" show={loginError.failure}>
                                                    <button
                                                        aria-hidden={true}
                                                        className="close"
                                                        data-dismiss="alert"
                                                        type="button"
                                                        onClick={() => setLoginError({failure: false, message: ''})}
                                                    >
                                                        <i className="nc-icon nc-simple-remove"/>
                                                    </button>
                                                    <span>
                                                        {loginError.message}
                                                    </span>
                                                </Alert>
                                                <div className="text-center">
                                                    <img
                                                        src={process.env.PUBLIC_URL + '/Logo transparent copy.png'}
                                                        alt="..."
                                                    />
                                                </div>
                                            </Card.Header>
                                            <Card.Body>
                                                <Card.Body>
                                                    <Form.Group>
                                                        <label>Email address</label>
                                                        <Form.Control
                                                            name="email"
                                                            placeholder="Enter email"
                                                            type="email"
                                                        />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <label>Password</label>
                                                        <Form.Control
                                                            name="password"
                                                            placeholder="Password"
                                                            type="password"
                                                        />
                                                    </Form.Group>
                                                </Card.Body>
                                            </Card.Body>
                                            <Card.Footer className="ml-auto mr-auto">
                                                <Button className="btn-wd" type="submit" variant="success">
                                                    Login
                                                </Button>
                                            </Card.Footer>
                                        </Card>
                                    </Form>
                                </Col>
                            </Container>
                        </div>
                        <div
                            className="full-page-background"
                            style={{
                                backgroundImage:
                                    "url(" +
                                    require("assets/img/shutterstock_898276.jpg").default +
                                    ")",
                            }}
                        />
                    </div> : <Loading message="Please wait while we sign you in :)"/>

            }
        </>
    );
}

export default LoginPage;
