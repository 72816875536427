import { apiProvider } from './index';

export class ApiCore {
    constructor(options) {
        if (options.get) {
            this.get = (endPoint) => {
                return apiProvider.get(endPoint);
            };
        }

        if (options.post) {
            this.post = (endPoint, model) => {
                return apiProvider.post(endPoint, model);
            };
        }

        if (options.put) {
            this.put = (endPoint, model) => {
                return apiProvider.put(endPoint, model);
            };
        }

        if (options.del) {
            this.del = (endPoint, id) => {
                return apiProvider.del(endPoint, id);
            };
        }
    }
}