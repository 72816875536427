import React from "react";
import {Switch, Route} from "react-router-dom";

// core components for the admin layout
import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbar/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";

import routes from "../routes/routes.js";
import image4 from "../assets/img/shutterstock_898276.jpg";

/**
 * Creates the layout to contain all admin routes using proper admin header/footer for the SPA
 *
 * @returns Admin Layout Component
 */
const Admin = () => {
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.layout === "/admin") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        key={key}
                        component={prop.component}
                    />
                );
            } else {
                return null;
            }
        });
    };

    return (
        <>
            <div className="wrapper">
                <Sidebar
                    routes={routes}
                    image={image4}
                    background={"black"}
                />
                <div className="main-panel">
                    <AdminNavbar/>
                    <div className="content">
                        <Switch>{getRoutes(routes)}</Switch>
                    </div>
                    <AdminFooter/>
                    <div
                        className="close-layer"
                        onClick={() =>
                            document.documentElement.classList.toggle("nav-open")
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default Admin;
