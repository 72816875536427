import {Button, Col, Form, Modal, Row} from 'react-bootstrap';
import React, {useContext, useState} from 'react';
import {Context as AdContext} from './AdContext';
import Loading from '../../components/Loading/Loading';
import {validateAdData} from './ads.validation';
import uploadImageServiceApi from "../../services/api/uploadImage";

const AdForm = ({onSubmit, initialValues}) => {
    const {state, setSubmittingForm} = useContext(AdContext);
    const [companyName, setCompanyName] = useState(initialValues?.companyName || '');
    const [description, setDescription] = useState(
        initialValues?.description || '');
    const [link, setLink] = useState(initialValues?.link || '');
    const [image, setImage] = useState(initialValues?.image || '');
    const [errors, setErrors] = useState({});

    const handleOnSubmit = async () => {
        let img = image;
        setSubmittingForm(true);
        if (typeof image !== 'string') {
            let upload = await uploadImage();
            if (!upload.success) {
                return alert('failed to upload image');
            }

            img = `${process.env.REACT_APP_SPACES_ENDPOINT}${upload.imageName}`;
        }
        let errors = await validateAdData(
            {companyName, description, link, image: img});
        if (errors) {
            setErrors(errors);
        } else {
            onSubmit({
                    companyName,
                    description,
                    link,
                    image: img
                },
                initialValues?.id);
        }

    };

    const handleChange = (event) => {
        switch (event.target.name) {
            case 'companyName':
                return setCompanyName(event.target.value);
            case 'description':
                return setDescription(event.target.value);
            case 'link':
                return setLink(event.target.value);
            default:
                return false
        }
    };

    const selectImage = (event) => {
        setImage({
            currentFile: event.target.files[0],
            previewImage: URL.createObjectURL(event.target.files[0]),
            progress: 0,
            message: ""
        });
    }

    const uploadImage = async () => {
        setImage({
            ...image,
            progress: 0
        });

        let res = await uploadImageServiceApi.upload(image.currentFile, (event) => {
            setImage({
                ...image,
                progress: Math.round((100 * event.loaded) / event.total),
            });
        })
        return res;
    }

    return (
        !state.submittingForm ?
            <Form>
                <Row>
                    <Col sm="6">
                        <Form.Group>
                            <label>Company Name</label>
                            <Form.Control
                                required
                                data-cy="companyName"
                                name="companyName"
                                onChange={handleChange}
                                placeholder="Enter Company Name"
                                value={companyName ?? ''}
                                isInvalid={!!errors.companyName}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.companyName}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="6">
                        <Form.Group>
                            <label>Description</label>
                            <Form.Control
                                required
                                data-cy="description"
                                name="description"
                                onChange={handleChange}
                                placeholder="Enter Description"
                                value={description ?? ''}
                                isInvalid={!!errors.description}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.description}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="6">
                        <Form.Group>
                            <label>Link</label>
                            <Form.Group>
                                <Form.Control
                                    data-cy="link"
                                    name="link"
                                    onChange={handleChange}
                                    placeholder="Enter Link: https://example.com"
                                    value={link ?? ''}
                                    isInvalid={!!errors.link}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.link}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col sm="6">
                        <Form.Group>
                            <label>Image</label>
                            <Form.Group>
                                <Form.Control className="form-control" type="file" accept="image/*"
                                              onChange={selectImage}
                                              data-cy="image"
                                              name="image"
                                              isInvalid={!!errors.image}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.image}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                </Row>
                <Modal.Footer>
                    <Button variant="primary" className="submit-btn"
                            onClick={handleOnSubmit}
                            data-cy="submitAd">
                        Submit
                    </Button>
                </Modal.Footer>
            </Form>
            : <Loading message="Submitting Form" showLogo={true}/>
    );
};

export default AdForm;
