import React from "react";
import {Provider as AdProvider} from "./AdContext";
import Ads from "./Ads";
import {ModalProvider} from "../../components/Modal/ModalContext";

const AdsIndex = () => {
    return (
        <AdProvider>
            <ModalProvider>
                <Ads/>
            </ModalProvider>
        </AdProvider>
    );
};

export default AdsIndex;
