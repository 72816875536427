import React from 'react';
import {Button} from 'react-bootstrap';
import SweetAlert from "react-bootstrap-sweetalert";

export const WarningAlert = ({onConfirm, onCancel, message, warningMessage = 'Yes, delete it!'}) =>
    <>
        <SweetAlert
            warning
            style={{display: "block", marginTop: "-100px"}}
            title="Are you sure?"
            onConfirm={onConfirm}
            onCancel={onCancel}
            customButtons={
                <React.Fragment>
                    <Button
                        className="btn btn-lg btn-danger"
                        data-cy="warningCancel"
                        type="button"
                        variant="primary"
                        style={{marginRight: '8px'}}
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn btn-lg btn-info"
                        data-cy="warningConfirm"
                        type="button"
                        variant="primary"
                        style={{
                            marginRight: '8px',
                            borderColor: "rgb(70, 184, 218)",
                            boxShadow: "rgba(0, 0, 0, 0.075) 0px 1px 1px inset, rgba(70, 184, 218, 0.6) 0px 0px 8px"
                        }}
                        onClick={onConfirm}
                    >
                        {warningMessage}
                    </Button>
                </React.Fragment>
            }
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
        >
            {message}
        </SweetAlert>
    </>;

export const SuccessAlert = ({title, onConfirm, onCancel, message}) =>
    <>
        <SweetAlert
            success
            style={{display: "block", marginTop: "-100px"}}
            title={title}
            onConfirm={onConfirm}
            onCancel={onCancel}
            confirmBtnBsStyle="info"
            customButtons={
                <React.Fragment>
                    <Button
                        className="btn btn-lg btn-info"
                        data-cy="successConfirm"
                        type="button"
                        variant="primary"
                        style={{
                            marginRight: '8px',
                            borderColor: "rgb(70, 184, 218)",
                            boxShadow: "rgba(0, 0, 0, 0.075) 0px 1px 1px inset, rgba(70, 184, 218, 0.6) 0px 0px 8px"
                        }}
                        onClick={onConfirm}
                    >
                        OK
                    </Button>
                </React.Fragment>
            }
        >
            {message}
        </SweetAlert>
    </>;

export const DangerAlert = ({title, onConfirm, onCancel, message}) =>
    <>
        <SweetAlert
            danger
            style={{display: "block", marginTop: "-100px"}}
            title={title}
            onConfirm={onConfirm}
            onCancel={onCancel}
            customButtons={
                <React.Fragment>
                    <Button
                        className="btn btn-lg btn-info"
                        style={{
                            marginRight: '8px',
                            borderColor: "rgb(70, 184, 218)",
                            boxShadow: "rgba(0, 0, 0, 0.075) 0px 1px 1px inset, rgba(70, 184, 218, 0.6) 0px 0px 8px"
                        }}
                        data-cy="dangerConfirm"
                        type="button"
                        variant="primary"
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn btn-lg btn-info"
                        style={{
                            marginRight: '8px',
                            borderColor: "rgb(70, 184, 218)",
                            boxShadow: "rgba(0, 0, 0, 0.075) 0px 1px 1px inset, rgba(70, 184, 218, 0.6) 0px 0px 8px"
                        }}
                        data-cy="dangerCancel"
                        type="button"
                        variant="primary"
                        onClick={onConfirm}
                    >
                        OK
                    </Button>
                </React.Fragment>
            }
            confirmBtnBsStyle="info"
        >
            {message}
        </SweetAlert>
    </>;