import React, {useEffect, useContext} from 'react'
import ReactTable from '../../components/ReactTable/ApiSearchReactTable'
import {MainContent} from 'components/Main/MainContent'
import {
    SuccessAlert,
    WarningAlert,
    DangerAlert,
} from 'components/Alerts/Alerts'
import {createFilterStringFromSearchValues} from '../Pages.utils'
import {Context as AdContext} from './AdContext'
import {useModal} from '../../components/Modal/ModalContext'
import AdForm from './AdForm'

const Ads = () => {
    const {
        state,
        getAds,
        setLoading,
        setAlert,
        createAd,
        updateAd,
        deleteAd,
    } = useContext(AdContext)
    const {setModal, unSetModal} = useModal()

    const add = () => {
        setModal({
            title: 'Add Ad', content: <AdForm onSubmit={(data) => {
                createAd(data, errorHandler)
            }}/>, show: true,
        })
    }

    const errorHandler = (success, message) => {
        unSetModal()
        if (!success) {
            return setAlert(<DangerAlert title="Failure!"
                                         onConfirm={hideAlert}
                                         onCancel={hideAlert}
                                         message={message}/>)
        }
        getAllAds()
        setAlert(<SuccessAlert title="Success!" onConfirm={hideAlert}
                               onCancel={hideAlert}
                               message={message}/>,
        )
    }

    const edit = (ad) => {
        setModal({
            title: 'Update Ad',
            content: <AdForm onSubmit={(data, id) => {
                updateAd(data, id, errorHandler)
            }} initialValues={ad}/>,
            show: true,
        })
    }

    const getAllAds = () => {
        setLoading(true)
        getAds(createFilterStringFromSearchValues(state.searchValues), edit,
            warningWithConfirmMessage)
    }

    const hideAlert = () => {
        setAlert(null)
    }

    const warningWithConfirmMessage = (data) => {
        setAlert(
            <WarningAlert onConfirm={() => deleteAd(data, errorHandler)}
                          onCancel={hideAlert}
                          message="You will not be able to recover this Ad!"/>,
        )
    }

    // eslint-disable-next-line
    useEffect(getAllAds, [state.searchValues])

    return (
        <>
            {state.alert}
            <MainContent title="Ads" addButtonId="addAd"
                         context={AdContext} add={add}>
                <ReactTable
                    data-cy="adTable"
                    context={AdContext}
                />
            </MainContent>
        </>
    )
}

export default Ads
