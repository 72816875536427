import Dashboard from "../pages/Dashboard";
import CityOffersIndex from "../pages/CityOffers/CItyOffersIndex";
import AdsIndex from "../pages/Ads/AdsIndex";
import EventsIndex from "../pages/Events/EventsIndex";
import RequestsIndex from "pages/Requests/RequestsIndex"

const dashboardRoutes = [
    {
        component: Dashboard,
        icon: "nc-icon nc-chart-pie-35",
        layout: "/admin",
        name: "Dashboard",
        path: "/dashboard",
        roles: []
    },
    {
        component: CityOffersIndex,
        icon: "nc-icon nc-tag-content",
        layout: "/admin",
        name: "City Offers",
        path: "/city-offers",
        roles: []
    },
    {
        component: RequestsIndex,
        icon: "nc-icon nc-single-copy-04",
        layout: "/admin",
        name: "Requests",
        path: "/requests",
        roles: []
    },
    {
        component: AdsIndex,
        icon: "nc-icon nc-paper-2",
        layout: "/admin",
        name: "Ads",
        path: "/ads",
        roles: []
    },
    {
        component: EventsIndex,
        icon: "nc-icon nc-square-pin",
        layout: "/admin",
        name: "Events",
        path: "/events",
        roles: []
    }
];

export default dashboardRoutes;
