import React, { useEffect, useContext } from 'react'
import ReactTable from '../../components/ReactTable/ApiSearchReactTable'
import { MainContent } from 'components/Main/MainContent'
import {
    SuccessAlert,
    WarningAlert,
    DangerAlert,
} from 'components/Alerts/Alerts'
import { createFilterStringFromSearchValues } from '../Pages.utils'
import { Context as EventContext } from './EventContext'
import { useModal } from '../../components/Modal/ModalContext'
import EventForm from './EventForm'
import { Button } from 'react-bootstrap'

const Events = () => {
    const {
        state,
        getEvents,
        getData,
        setLoading,
        setAlert,
        createEvent,
        updateEvent,
        deleteEvent,
        deletePastEvents
    } = useContext(EventContext)
    const { setModal, unSetModal } = useModal()

    const add = () => {
        setModal({
            title: 'Add Event', content: <EventForm onSubmit={(data) => {
                createEvent(data, errorHandler)
            }} />, show: true,
        })
    }

    const errorHandler = (success, message) => {
        unSetModal()
        if (!success) {
            return setAlert(<DangerAlert title="Failure!"
                onConfirm={hideAlert}
                onCancel={hideAlert}
                message={message} />)
        }
        getAllEvents()
        setAlert(<SuccessAlert title="Success!" onConfirm={hideAlert}
            onCancel={hideAlert}
            message={message} />,
        )
    }

    const edit = (event) => {
        setModal({
            title: 'Update Event',
            content: <EventForm onSubmit={(data, id) => {
                updateEvent(data, id, errorHandler)
            }} initialValues={event} />,
            show: true,
        })
    }

    const getAllEvents = () => {
        setLoading(true)
        getEvents(createFilterStringFromSearchValues(state.searchValues), edit,
            warningWithConfirmMessage)
    }

    const getNextPage = () => {
        getData(createFilterStringFromSearchValues(state.searchValues), edit,
            warningWithConfirmMessage, state.data, true)
    }

    const getPrevPage = () => {
        getData(createFilterStringFromSearchValues(state.searchValues), edit,
            warningWithConfirmMessage, state.data, false)
    }

    const hideAlert = () => {
        setAlert(null)
    }

    const warningWithConfirmMessage = (data) => {
        setAlert(
            <WarningAlert onConfirm={() => deleteEvent(data, errorHandler)}
                onCancel={hideAlert}
                message="You will not be able to recover this Event!" />,
        )
    }

    const warningDeletePastWithConfirmMessage = () => {
        setAlert(
            <WarningAlert onConfirm={() => deletePastEvents(errorHandler)}
                onCancel={hideAlert}
                message="You will not be able to recover Past Events!" />,
        )
    }

    // eslint-disable-next-line
    useEffect(getAllEvents, [state.searchValues])

    return (
        <>
            {state.alert}
            <MainContent title="Events" addButtonId="addEvent"
                context={EventContext} add={add} del={warningDeletePastWithConfirmMessage}>
                <ReactTable
                    data-cy="eventTable"
                    context={EventContext}
                    getNextPage={getNextPage}
                />
            </MainContent>
        </>
    )
}

export default Events
