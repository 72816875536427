import { ApiCore } from "./utilities"

const eventsApi = new ApiCore({
    get: true,
    post: true,
    put: true,
    del: true
})

// Add custom api calls
eventsApi.getAllEvents = (filter = '') => eventsApi.get(`/event${filter}`)

eventsApi.getEvent = (id) => eventsApi.get(`/event/${id}`)

eventsApi.deleteEvent = (id) => eventsApi.del(`/event/${id}`)

eventsApi.updateEvent = (event, id) => eventsApi.put(`/event/${id}`, event)

eventsApi.createEvent = (event) => eventsApi.post(`/event`, event)

eventsApi.deletePastEvents = () => eventsApi.del(`/event/clean/past`)

eventsApi.getMostTagsOccurred = () => eventsApi.get(`/event/get-most-occurred-tags`)

eventsApi.getAllTags = (filter = '') => eventsApi.get(`/event/get-all-tags${filter}`)

eventsApi.getPopularTags = () => eventsApi.get(`/event/get-popular-tags`)

eventsApi.getAllEventsByDistanceNoPagination = (filter = '') => eventsApi.get(`/event/no-pagination/get-by-distance${filter}`)

eventsApi.getAllEventsNoPagination = (filter = '') => eventsApi.get(`/event/no-pagination${filter}`)

export default eventsApi
