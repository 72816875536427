import React, {useContext} from "react";
import {
    useTable,
    useSortBy,
    usePagination,
} from "react-table";
import classnames from "classnames";
import Select from "react-select";
import {Container, Row, Col, FormGroup} from "reactstrap";
import Loading from "components/Loading/Loading";
import {DebounceInput} from 'react-debounce-input';

const ApiSearchReactTable = ({dataCy, context, getNextPage}) => {
    const {state, setSearchValues} = useContext(context)
    const [numberOfRows, setNumberOfRows] = React.useState({
        value: 10,
        label: "10 rows",
    });
    const [pageSelect, handlePageSelect] = React.useState({
        value: 0,
        label: "Page 1",
    });

    const handleSearchInputChange = (event) => {
        setSearchValues({...state.searchValues, [event.target.id]: event.target.value})
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        pageSize,
        prepareRow,
        nextPage,
        pageOptions,
        previousPage,
        canPreviousPage,
        canNextPage,
        setPageSize,
        gotoPage,
    } = useTable(
        {
            columns: state.tableColumns,
            data: state.data,
            initialState: {pageSize: 10, pageIndex: 0},
        },
        useSortBy,
        usePagination
    );

    const next = () => {
        if (!canNextPage) {
            getNextPage();
        } else {
            nextPage()
        }
    }

    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    // const firstPageRows = rows.slice(0, 10);
    let pageSelectData = Array.apply(
        null,
        Array(pageOptions.length)
    ).map(el => el);
    let numberOfRowsData = [5, 10, 20, 25, 50, 100];
    return (
        <>
            <div id={dataCy} data-cy={dataCy} className="ReactTable -striped -highlight primary-pagination">
                <div className="pagination-top">
                    <div className="-pagination">
                        <div className="-previous">
                            <button
                                type="button"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                                className="-btn"
                            >
                                Previous
                            </button>
                        </div>
                        <div className="-center">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col md="4" sm="6" xs="12">
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="pageSelect"
                                            value={pageSelect}
                                            onChange={(value) => {
                                                gotoPage(value.value);
                                                handlePageSelect(value);
                                            }}
                                            options={pageSelectData.map((prop, key) => {
                                                return {
                                                    value: key,
                                                    label: "Page " + (key + 1),
                                                };
                                            })}
                                            placeholder="Choose Page"
                                        />
                                    </Col>
                                    <Col md="4" sm="6" xs="12">
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="numberOfRows"
                                            value={numberOfRows}
                                            onChange={(value) => {
                                                setPageSize(value.value);
                                                setNumberOfRows(value);
                                            }}
                                            options={numberOfRowsData.map((prop) => {
                                                return {
                                                    value: prop,
                                                    label: prop + " rows",
                                                };
                                            })}
                                            placeholder="Choose Rows"
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="-next">
                            <button
                                type="button"
                                onClick={next}
                                className="-btn"
                                disabled={!(state.data.count > pageSize) ? true : false}
                            >
                                {!canNextPage ? "Next" : "Load More"}
                            </button>
                        </div>
                    </div>
                </div>
                <table {...getTableProps()} className="rt-table">
                    <thead className="rt-thead -header">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                            {headerGroup.headers.map((column, key) => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className={classnames("rt-th rt-resizable-header", {
                                        "-cursor-pointer": headerGroup.headers.length - 1 !== key,
                                        "-sort-asc": column.isSorted && !column.isSortedDesc,
                                        "-sort-desc": column.isSorted && column.isSortedDesc,
                                    })}
                                >
                                    <div className="rt-resizable-header-content">
                                        {column.render("Header")}
                                    </div>
                                    <div>
                                        {column.id !== 'actions' &&
                                        <FormGroup>
                                            <DebounceInput
                                                data-cy={`SearchFilter${column.id}`}
                                                id={column.id}
                                                name={column.id}
                                                value={state.searchValues[column.id]}
                                                className="form-control"
                                                placeholder={`Search records...`}
                                                type="text"
                                                debounceTimeout={500}
                                                onChange={handleSearchInputChange}/>
                                        </FormGroup>}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="rt-tbody">
                    {!state.loading ?
                        (page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    className={classnames(
                                        "rt-tr",
                                        {" -odd": i % 2 === 0},
                                        {" -even": i % 2 === 1}
                                    )}
                                >
                                    {row.cells.map((cell) => {
                                        return (
                                            <td data-cy={`${dataCy}_${cell.column.id}`} {...cell.getCellProps()}
                                                className="rt-td">
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })) : <tr className="splash-screen">
                            <td colSpan="100%"><Loading message="Loading Data" showLogo={true}/></td>
                        </tr>
                    }
                    </tbody>

                </table>
                <div className="pagination-bottom"/>
            </div>
        </>
    );
};

export default ApiSearchReactTable;
