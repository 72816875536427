import React, { useEffect, useContext } from 'react'
import ReactTable from '../../components/ReactTable/ApiSearchReactTable'
import { MainContent } from 'components/Main/MainContent'
import {
  SuccessAlert,
  WarningAlert,
  DangerAlert,
} from 'components/Alerts/Alerts'
import { createFilterStringFromSearchValues } from '../Pages.utils'
import { Context as CityOfferContext } from './CityOfferContext'
import { useModal } from '../../components/Modal/ModalContext'
import CityOfferForm from './CityOfferForm'

const CityOffers = () => {
  const {
    state,
    getCityOffers,
    setLoading,
    setAlert,
    createCityOffer,
    updateCityOffer,
    deleteCityOffer,
  } = useContext(CityOfferContext)
  const { setModal, unSetModal } = useModal()

  const add = () => {
    setModal({
      title: 'Add City Offer', content: <CityOfferForm onSubmit={(data) => {
        createCityOffer(data, errorHandler)
      }}/>, show: true,
    })
  }

  const errorHandler = (success, message) => {
    unSetModal()
    if (!success) {
      return setAlert(<DangerAlert title="Failure!"
                                   onConfirm={hideAlert}
                                   onCancel={hideAlert}
                                   message={message}/>)
    }
    getAllCityOffers()
    setAlert(<SuccessAlert title="Success!" onConfirm={hideAlert}
                           onCancel={hideAlert}
                           message={message}/>,
    )
  }

  const edit = (cityOffer) => {
    setModal({
      title: 'Update City Offer',
      content: <CityOfferForm onSubmit={(data, id) => {
        updateCityOffer(data, id, errorHandler)
      }} initialValues={cityOffer}/>,
      show: true,
    })
  }

  const getAllCityOffers = () => {
    setLoading(true)
    getCityOffers(createFilterStringFromSearchValues(state.searchValues), edit,
      warningWithConfirmMessage)
  }

  const hideAlert = () => {
    setAlert(null)
  }

  const warningWithConfirmMessage = (data) => {
    setAlert(
      <WarningAlert onConfirm={() => deleteCityOffer(data, errorHandler)}
                    onCancel={hideAlert}
                    message="You will not be able to recover this City Offer!"/>,
    )
  }

    // eslint-disable-next-line
  useEffect(getAllCityOffers, [state.searchValues])

  return (
    <>
      {state.alert}
        <MainContent title="City Offers" addButtonId="addCityOffer"
                     context={CityOfferContext} add={add}>
          <ReactTable
            data-cy="cityOfferTable"
            context={CityOfferContext}
          />
        </MainContent>
    </>
  )
}

export default CityOffers
